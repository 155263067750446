<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-14 09:29:36
 * @Description: 消息通知
-->
<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.page-notify {
  @include innerPage;
  @include pageHead(-42px);
  @include defalutTable(calc(100% - 162px), 10px);
  @include pageFoot;

}
.content{
  height: calc(100vh - 240px);
  overflow: auto;
  padding: 20px 0;
		.list{
      position: relative;
      min-height: 200px;
			width: 100%;
			.item{
				width: 100%;
				padding: 10px;
				background-color: #FFFFFF;
				border-radius: 10px;
				margin-bottom: 20px;
				.iconld{
					width: 28px;
					height: 28px;
					background: #01A1FD;
					border-radius: 50%;
					text-align: center;
					line-height: 28px;
					position: relative;
					margin-right: 10px;
					.dian{
						width: 16px;
						height: 16px;
						background: #FF4D4F;
						border: 2px solid #FFFFFF;
						border-radius: 50%;
						position: absolute;
						right: 0;
						top: -2px;
					}
          .iconfont{
            font-size: 22px;
            color: #FFFFFF;
          }
				}
				.name{
					height: 34px;
          font-size: 18px;
          font-weight: bold;
          color: #222222;
          line-height: 34px;
				}
				.p{
					margin-top: 6px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
          line-height: 24px;
          margin-bottom: 10px;
				}
				&.already{
					.iconld{
						background: #AAAAAA;
					}
					.name{
						color: #888888;
					}
				}
        &:hover{
          opacity: 0.9;
          cursor: pointer;
					.iconld{
						background: #333333;
					}
					.name{
						color: #333333;
					}
          
        }
			}
		}
	}
</style>

<template>
  <section class="page-notify">
    <div class="table-box">
      <div class="content">
        <div class="list">
          <div v-for="(item,index) in list" :key="index">
            <div class="item" :class="item.plstu_mes_status==10?'already':''" @click="goDetails(item,index)">
              <div class="flex_align">
                <div class="iconld" :class="item.plstu_mes_status==10?'type1':''">
                  <div class="dian" v-if="item.plstu_mes_status!=10"></div>
                  <div class="iconfont">&#xe644;</div>
                </div>
                <div class="flex_1 name clamp1">{{item.plstu_mes_title}}</div>
              </div>
              <div class="p">
                <div v-html="item.plstu_mes_content"></div>
              </div>
              <div class="flex_bet_align mt-23">
                <div class="fs-24 c-8">{{item.create_time}}</div>
                <div class="fs-28 c-orange">查看详情</div>
              </div>
            </div>
          </div>
          <div class="no-data--empty" v-if="$isEmpty(list)">
              <img src="@assets/images/no-data3.png" alt="">
              <p>暂无消息</p>
          </div>
        </div>
      </div>
      <div class="table-foot custom-foot">
        <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
      </div>
    </div>
  </section>
</template>

<script>
import { get_home_noticelist} from "@api/home"
import customPagination from "@comp/customPagination"
export default {
  name: "home_notify",
  components: { customPagination },
  data() {
    return {
      list: [],
      tablePage: {
        pageIndex: 1,
        total: 0
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /** 获取消息列表 */
    async getData() {
      let params = {
        pageindex: this.tablePage.pageIndex
      }
      let { data: res } = await get_home_noticelist(params);
      this.tablePage.total = res.allcount;
      this.list = res.data;
      this.$forceUpdate();
    },
    /** 翻页 */
    flippingPage(val) {
      this.tablePage.pageIndex = val;
      this.getData();
    },
    // 跳转详情
			goDetails(item,index){
				this.list[index].plstu_mes_status=10
				// 消息类型 10通知消息 20跳转通知消息
				if(item.plstu_mes_type==10){
          this.$router.push({ name: 'HOME_NOTIFYDETAIL1',params: { id: item.plstu_mes_id }})
				}else{
					// 跳转内部
					if(item.plstu_mes_jumptype==10){
						if(item.plstu_mes_relation_type==10){
							//意见反馈消息
               this.$router.push({ name: 'HOME_NOTIFYDETAIL2',params: { id: item.plstu_mes_id }})
						}else{
							this.$plug.goNavigateTo(item.plstu_mes_herf)
						}
					}else{
						// 跳转外部
            this.$router.push({ name: 'HOME_NOTIFYDETAIL3',params: { url: item.plstu_mes_herf }})
					}
				}
			},
  },
};
</script>
